import { Swiper, Navigation, Pagination } from "swiper/dist/js/swiper.esm.js";

Swiper.use([Navigation, Pagination]);

export default () => {
    // Instâncias do Swiper aqui...

    new Swiper("#swiper-container-noticias", {
        spaceBetween: 10,
        slidesPerView: 4,
        navigation: {
            prevEl: ".mais-noticias-prev",
            nextEl: ".mais-noticias-next"
        },
        breakpoints: {
            920: {
                slidesPerView: 2,
                spaceBetween: 10
            }
        },
        breakpoints: {
            599: {
                slidesPerView: 1,
                spaceBetween: 10
            }
        }
    });
};
